/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuctionExcludingIdCreatedStatusLangIdWithRelations } from './auctionExcludingIdCreatedStatusLangIdWithRelations';
import { ExhibitionLangExcludingIdCreatedStatusLangIdWithRelations } from './exhibitionLangExcludingIdCreatedStatusLangIdWithRelations';
import { ItemExcludingIdCreatedStatusLangIdWithRelations } from './itemExcludingIdCreatedStatusLangIdWithRelations';
import { PressReleaseExcludingIdCreatedStatusLangIdWithRelations } from './pressReleaseExcludingIdCreatedStatusLangIdWithRelations';


/**
 * (tsType: Omit<ExhibitionWithRelations, \'id\' | \'created\' | \'status\' | \'langId\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'created\', \'status\', \'langId\' ] })
 */
export interface ExhibitionExcludingIdCreatedStatusLangIdWithRelations { 
    auctionId?: number;
    exhibitionFrom: string;
    exhibitionTo: string;
    mainExhibition?: boolean;
    matterportId?: string | null;
    youtubeVideoId?: string | null;
    archived?: boolean;
    visible?: boolean;
    showPrices?: boolean | null;
    lastModified?: string | null;
    auction?: AuctionExcludingIdCreatedStatusLangIdWithRelations;
    foreignKey?: any | null;
    lang?: ExhibitionLangExcludingIdCreatedStatusLangIdWithRelations;
    langs?: Array<ExhibitionLangExcludingIdCreatedStatusLangIdWithRelations>;
    items?: Array<ItemExcludingIdCreatedStatusLangIdWithRelations>;
    pressReleases?: Array<PressReleaseExcludingIdCreatedStatusLangIdWithRelations>;
}

