/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: CountryWithRelations, schemaOptions: { includeRelations: true })
 */
export interface CountryWithRelations { 
    id?: number;
    name: string;
    nameCs: string;
    isoCode2?: string | null;
    isoCode3?: string | null;
    status?: number | null;
    order?: number | null;
}

