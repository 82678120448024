/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuctionFileExcludingIdCreatedStatusLangIdWithRelations } from './auctionFileExcludingIdCreatedStatusLangIdWithRelations';
import { AuctionLangExcludingIdCreatedStatusLangIdWithRelations } from './auctionLangExcludingIdCreatedStatusLangIdWithRelations';
import { AuctionStatusExcludingIdCreatedStatusLangIdWithRelations } from './auctionStatusExcludingIdCreatedStatusLangIdWithRelations';
import { ExhibitionExcludingIdCreatedStatusLangIdWithRelations } from './exhibitionExcludingIdCreatedStatusLangIdWithRelations';
import { ItemExcludingIdCreatedStatusLangIdWithRelations } from './itemExcludingIdCreatedStatusLangIdWithRelations';
import { PressReleaseExcludingIdCreatedStatusLangIdWithRelations } from './pressReleaseExcludingIdCreatedStatusLangIdWithRelations';


/**
 * (tsType: Omit<AuctionWithRelations, \'id\' | \'created\' | \'status\' | \'langId\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'created\', \'status\', \'langId\' ] })
 */
export interface AuctionExcludingIdCreatedStatusLangIdWithRelations { 
    auctionNumber?: string | null;
    internetAuction?: boolean;
    fee?: number | null;
    limitationFrom?: string | null;
    limitationTo?: string | null;
    auctionFrom?: string | null;
    youtubeVideoId?: string | null;
    mainAuction?: boolean;
    limitationAvailable?: boolean | null;
    archived?: boolean;
    canvasId?: number | null;
    artslimitId?: number | null;
    exhibitionId?: number | null;
    canOrderCatalog?: boolean | null;
    visible?: boolean;
    auctionStatus?: AuctionStatusExcludingIdCreatedStatusLangIdWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionExcludingIdCreatedStatusLangIdWithRelations;
    files?: Array<AuctionFileExcludingIdCreatedStatusLangIdWithRelations>;
    lang?: AuctionLangExcludingIdCreatedStatusLangIdWithRelations;
    langs?: Array<AuctionLangExcludingIdCreatedStatusLangIdWithRelations>;
    items?: Array<ItemExcludingIdCreatedStatusLangIdWithRelations>;
    pressReleases?: Array<PressReleaseExcludingIdCreatedStatusLangIdWithRelations>;
}

