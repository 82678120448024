/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<PressReleaseLangWithRelations, \'id\' | \'created\' | \'status\' | \'langId\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'created\', \'status\', \'langId\' ] })
 */
export interface PressReleaseLangExcludingIdCreatedStatusLangIdWithRelations { 
    pressReleaseId?: number;
    title: string;
    filename?: string | null;
}

