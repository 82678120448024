/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemWithRelations } from './itemWithRelations';


/**
 * (tsType: LimitationWithRelations, schemaOptions: { includeRelations: true })
 */
export interface LimitationWithRelations { 
    id?: number;
    limitationBaseId?: number | null;
    itemId?: number;
    value?: number | null;
    /**
     * 1: Telephone, 2: Fixed limit
     */
    type: LimitationWithRelations.TypeEnum;
    note?: string | null;
    created?: string;
    item?: ItemWithRelations;
    foreignKey?: any | null;
}
export namespace LimitationWithRelations {
    export type TypeEnum = 1 | 2;
    export const TypeEnum = {
        NUMBER_1: 1 as TypeEnum,
        NUMBER_2: 2 as TypeEnum
    };
}


