/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuctionExcludingIdCreatedStatusLangIdWithRelations } from './auctionExcludingIdCreatedStatusLangIdWithRelations';
import { ItemLangExcludingIdCreatedStatusLangIdWithRelations } from './itemLangExcludingIdCreatedStatusLangIdWithRelations';
import { AuthorExcludingIdCreatedStatusLangIdWithRelations } from './authorExcludingIdCreatedStatusLangIdWithRelations';
import { ItemStatusExcludingIdCreatedStatusLangIdWithRelations } from './itemStatusExcludingIdCreatedStatusLangIdWithRelations';
import { ExhibitionExcludingIdCreatedStatusLangIdWithRelations } from './exhibitionExcludingIdCreatedStatusLangIdWithRelations';
import { AuthorRecordsExcludingIdCreatedStatusLangIdWithRelations } from './authorRecordsExcludingIdCreatedStatusLangIdWithRelations';
import { ItemPictureExcludingIdCreatedStatusLangIdWithRelations } from './itemPictureExcludingIdCreatedStatusLangIdWithRelations';


/**
 * (tsType: Omit<ItemWithRelations, \'id\' | \'created\' | \'status\' | \'langId\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'created\', \'status\', \'langId\' ] })
 */
export interface ItemExcludingIdCreatedStatusLangIdWithRelations { 
    auctionId?: number;
    exhibitionId?: number;
    size?: string | null;
    priceEstimationLow?: number | null;
    priceEstimationHigh?: number | null;
    priceCurrent?: number | null;
    priceHammer?: number | null;
    priceFinal?: number | null;
    startPriceForBidding?: number | null;
    startPriceForClosing?: number | null;
    catalogueNumber?: number | null;
    canvasId?: number | null;
    artslimitId?: number | null;
    lastModified?: string;
    auction?: AuctionExcludingIdCreatedStatusLangIdWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionExcludingIdCreatedStatusLangIdWithRelations;
    lang?: ItemLangExcludingIdCreatedStatusLangIdWithRelations;
    langs?: Array<ItemLangExcludingIdCreatedStatusLangIdWithRelations>;
    pictures?: Array<ItemPictureExcludingIdCreatedStatusLangIdWithRelations>;
    authors?: Array<AuthorExcludingIdCreatedStatusLangIdWithRelations>;
    itemStatus?: ItemStatusExcludingIdCreatedStatusLangIdWithRelations;
    record?: AuthorRecordsExcludingIdCreatedStatusLangIdWithRelations;
}

