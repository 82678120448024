/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PressReleaseExcludingIdWithRelations } from './pressReleaseExcludingIdWithRelations';
import { ItemExcludingIdWithRelations } from './itemExcludingIdWithRelations';
import { AuctionFileExcludingIdWithRelations } from './auctionFileExcludingIdWithRelations';
import { AuctionLangExcludingIdWithRelations } from './auctionLangExcludingIdWithRelations';
import { ExhibitionExcludingIdWithRelations } from './exhibitionExcludingIdWithRelations';
import { AuctionStatusExcludingIdWithRelations } from './auctionStatusExcludingIdWithRelations';


/**
 * (tsType: Omit<AuctionWithRelations, \'id\'>, schemaOptions: { exclude: [ \'id\' ], includeRelations: true })
 */
export interface AuctionExcludingIdWithRelations { 
    auctionNumber?: string | null;
    internetAuction?: boolean;
    fee?: number | null;
    limitationFrom?: string | null;
    limitationTo?: string | null;
    auctionFrom?: string | null;
    youtubeVideoId?: string | null;
    mainAuction?: boolean;
    limitationAvailable?: boolean | null;
    archived?: boolean;
    canvasId?: number | null;
    artslimitId?: number | null;
    exhibitionId?: number | null;
    canOrderCatalog?: boolean | null;
    visible?: boolean;
    created?: string;
    status?: number;
    auctionStatus?: AuctionStatusExcludingIdWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionExcludingIdWithRelations;
    files?: Array<AuctionFileExcludingIdWithRelations>;
    lang?: AuctionLangExcludingIdWithRelations;
    langs?: Array<AuctionLangExcludingIdWithRelations>;
    items?: Array<ItemExcludingIdWithRelations>;
    pressReleases?: Array<PressReleaseExcludingIdWithRelations>;
}

