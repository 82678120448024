/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorRecordsLangExcludingIdCreatedStatusLangIdWithRelations } from './authorRecordsLangExcludingIdCreatedStatusLangIdWithRelations';
import { AuthorExcludingIdCreatedStatusLangIdWithRelations } from './authorExcludingIdCreatedStatusLangIdWithRelations';
import { ItemExcludingIdCreatedStatusLangIdWithRelations } from './itemExcludingIdCreatedStatusLangIdWithRelations';


/**
 * (tsType: Omit<AuthorRecordsWithRelations, \'id\' | \'created\' | \'status\' | \'langId\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'created\', \'status\', \'langId\' ] })
 */
export interface AuthorRecordsExcludingIdCreatedStatusLangIdWithRelations { 
    authorId?: number;
    itemId?: number;
    type: AuthorRecordsExcludingIdCreatedStatusLangIdWithRelations.TypeEnum;
    position?: number | null;
    author?: AuthorExcludingIdCreatedStatusLangIdWithRelations;
    foreignKey?: any | null;
    item?: ItemExcludingIdCreatedStatusLangIdWithRelations;
    lang?: AuthorRecordsLangExcludingIdCreatedStatusLangIdWithRelations;
    langs?: Array<AuthorRecordsLangExcludingIdCreatedStatusLangIdWithRelations>;
}
export namespace AuthorRecordsExcludingIdCreatedStatusLangIdWithRelations {
    export type TypeEnum = 'local' | 'europe' | 'worldwide';
    export const TypeEnum = {
        Local: 'local' as TypeEnum,
        Europe: 'europe' as TypeEnum,
        Worldwide: 'worldwide' as TypeEnum
    };
}


