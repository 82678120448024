export * from './admin.service';
import { AdminService } from './admin.service';
export * from './artslimitImporter.service';
import { ArtslimitImporterService } from './artslimitImporter.service';
export * from './assetManager.service';
import { AssetManagerService } from './assetManager.service';
export * from './auction.service';
import { AuctionService } from './auction.service';
export * from './author.service';
import { AuthorService } from './author.service';
export * from './blog.service';
import { BlogService } from './blog.service';
export * from './canvasImporter.service';
import { CanvasImporterService } from './canvasImporter.service';
export * from './country.service';
import { CountryService } from './country.service';
export * from './downloadComponent.service';
import { DownloadComponentService } from './downloadComponent.service';
export * from './exhibition.service';
import { ExhibitionService } from './exhibition.service';
export * from './forms.service';
import { FormsService } from './forms.service';
export * from './frontend.service';
import { FrontendService } from './frontend.service';
export * from './gallery.service';
import { GalleryService } from './gallery.service';
export * from './imgController.service';
import { ImgControllerService } from './imgController.service';
export * from './item.service';
import { ItemService } from './item.service';
export * from './langController.service';
import { LangControllerService } from './langController.service';
export * from './limitation.service';
import { LimitationService } from './limitation.service';
export * from './menu.service';
import { MenuService } from './menu.service';
export * from './migrationController.service';
import { MigrationControllerService } from './migrationController.service';
export * from './news.service';
import { NewsService } from './news.service';
export * from './pages.service';
import { PagesService } from './pages.service';
export * from './partners.service';
import { PartnersService } from './partners.service';
export * from './pressRelease.service';
import { PressReleaseService } from './pressRelease.service';
export * from './reference.service';
import { ReferenceService } from './reference.service';
export * from './relink.service';
import { RelinkService } from './relink.service';
export * from './slider.service';
import { SliderService } from './slider.service';
export * from './teamMembers.service';
import { TeamMembersService } from './teamMembers.service';
export * from './userManagement.service';
import { UserManagementService } from './userManagement.service';
export * from './version.service';
import { VersionService } from './version.service';
export const APIS = [AdminService, ArtslimitImporterService, AssetManagerService, AuctionService, AuthorService, BlogService, CanvasImporterService, CountryService, DownloadComponentService, ExhibitionService, FormsService, FrontendService, GalleryService, ImgControllerService, ItemService, LangControllerService, LimitationService, MenuService, MigrationControllerService, NewsService, PagesService, PartnersService, PressReleaseService, ReferenceService, RelinkService, SliderService, TeamMembersService, UserManagementService, VersionService];
