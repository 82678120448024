/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorLangExcludingIdCreatedStatusLangIdWithRelations } from './authorLangExcludingIdCreatedStatusLangIdWithRelations';
import { ItemExcludingIdCreatedStatusLangIdWithRelations } from './itemExcludingIdCreatedStatusLangIdWithRelations';


/**
 * (tsType: Omit<AuthorWithRelations, \'id\' | \'created\' | \'status\' | \'langId\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'created\', \'status\', \'langId\' ] })
 */
export interface AuthorExcludingIdCreatedStatusLangIdWithRelations { 
    firstname?: string | null;
    lastname?: string | null;
    nickname?: string;
    birthday?: string | null;
    death?: string | null;
    canvasId?: number | null;
    lang?: AuthorLangExcludingIdCreatedStatusLangIdWithRelations;
    items?: Array<ItemExcludingIdCreatedStatusLangIdWithRelations>;
    langs?: Array<AuthorLangExcludingIdCreatedStatusLangIdWithRelations>;
}

