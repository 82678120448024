/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorRecordsLangWithRelations } from './authorRecordsLangWithRelations';
import { ItemWithRelations } from './itemWithRelations';
import { AuthorWithRelations } from './authorWithRelations';


/**
 * (tsType: AuthorRecordsWithRelations, schemaOptions: { includeRelations: true })
 */
export interface AuthorRecordsWithRelations { 
    id?: number;
    authorId?: number;
    itemId?: number;
    type: AuthorRecordsWithRelations.TypeEnum;
    position?: number | null;
    author?: AuthorWithRelations;
    foreignKey?: any | null;
    item?: ItemWithRelations;
    lang?: AuthorRecordsLangWithRelations;
    langs?: Array<AuthorRecordsLangWithRelations>;
}
export namespace AuthorRecordsWithRelations {
    export type TypeEnum = 'local' | 'europe' | 'worldwide';
    export const TypeEnum = {
        Local: 'local' as TypeEnum,
        Europe: 'europe' as TypeEnum,
        Worldwide: 'worldwide' as TypeEnum
    };
}


