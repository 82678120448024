import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import {ActivatedRoute, Router} from '@angular/router';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {LangsService} from './shared/services/langs.service';
import {AuthenticationService} from './shared/services/authentication.service';

@Component({
  selector: 'kodl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private configService: ConfigService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private router: Router,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              private readonly matIconRegistry: MatIconRegistry,
              private langsService: LangsService,
              private authService: AuthenticationService,
              private readonly domSanitizer: DomSanitizer) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `/assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `/assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `/assets/img/icons/flags/${name}.svg`
            );
          case 'mime':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `/assets/img/icons/mime/${name}.svg`
            );
        }
      }
    );


    this.navigationService.items = [
      {
        type: "subheading",
        label: "Hlavní",
        availableTo: ["Admin"],
        children: [
          {
            type: "link",
            label: "Nástěnka",
            route: "/",
            icon: "mat:insights",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: "link",
            label: "Aukce",
            route: "/aukce",
            icon: "mat:gavel",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: "link",
            label: "Výstavy",
            route: "/vystavy",
            icon: "mat:museum",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: "link",
            label: "Autoři",
            route: "/autori",
            icon: "mat:assignment_ind",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: "link",
            label: "Limitní lístky",
            route: "/limitni-listky",
            icon: "mat:confirmation_number",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: "dropdown",
            label: "Formuláře",
            icon: "mat:dynamic_form",
            availableTo: ["Admin"],
            children: [
              {
                type: "link",
                label: "Nabídnutá díla",
                route: "/formulare/nabidnuta-dila",
                availableTo: ["Admin"],
                routerLinkActiveOptions: {exact: false},
              },
              {
                type: "link",
                label: "Objednání katalogu",
                route: "/formulare/objednani-katalogu",
                availableTo: ["Admin"],
                routerLinkActiveOptions: {exact: false},
              }
            ],
          }
        ],
      },
      {
        type: "subheading",
        label: "Obsah",
        availableTo: ["Admin"],
        children: [
          {
            type: "link",
            label: "Aktuality",
            route: "/aktuality",
            icon: "mat:newspaper",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: "link",
            label: "Blog",
            route: "/blog",
            icon: "mat:library_books",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: "link",
            label: "Tiskové zprávy",
            route: "/tiskove-zpravy",
            icon: "mat:feed",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: "link",
            label: "Reference",
            route: "/reference",
            icon: "mat:stars",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: "link",
            label: "Stránky",
            route: "/obsah/stranky",
            icon: "mat:web",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: "dropdown",
            label: "Komponenty",
            icon: "mat:dvr",
            availableTo: ["Admin"],
            children: [
              {
                type: "link",
                label: "Slider",
                route: "/obsah/slider",
                icon: "mat:linear_scale",
                availableTo: ["Admin"],
                routerLinkActiveOptions: { exact: false },
              },
              {
                type: "link",
                label: "Galerie",
                route: "/obsah/galerie",
                icon: "mat:photo",
                availableTo: ["Admin"],
                routerLinkActiveOptions: { exact: false },
              },
              {
                type: "link",
                label: "Menu",
                route: "/obsah/menu",
                icon: "mat:menu",
                availableTo: ["Admin"],
                routerLinkActiveOptions: { exact: false },
              },
              {
                type: "link",
                label: "Ke stažení",
                route: "/obsah/ke-stazeni",
                icon: "mat:downloading",
                availableTo: ["Admin"],
                routerLinkActiveOptions: { exact: false },
              },
            ],
          },

          {
            type: "link",
            label: "Náš tým",
            route: "/nas-tym",
            icon: "mat:diversity_1",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: "link",
            label: "Partneři",
            route: "/obsah/partneri",
            icon: "mat:handshake",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
        ],
      },
      {
        type: "subheading",
        label: "Nastavení",
        availableTo: ["Admin"],
        children: [
          {
            type: "link",
            label: "Uživatelé systému",
            route: "/nastaveni/uzivatele-systemu",
            icon: "mat:manage_accounts",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: "link",
            label: "Routování stránek",
            route: "/nastaveni/relink",
            icon: "mat:leak_add",
            availableTo: ["Admin"],
            routerLinkActiveOptions: { exact: false },
          },
        ],
      },
      // {
      //   type: 'subheading',
      //   label: 'Demo-pages',
      //   children: [
      //     {
      //       type: 'link',
      //       label: 'Login',
      //       route: '/login',
      //       icon: 'mat:lock_open',
      //       availableTo: ['Admin'],
      //       routerLinkActiveOptions: { exact: true }
      //     },
      //     {
      //       type: 'link',
      //       label: 'Template',
      //       route: '/blank',
      //       icon: 'mat:check_box_outline_blank',
      //       availableTo: ['Admin', 'Management'],
      //       routerLinkActiveOptions: { exact: true }
      //     }
      //   ]
      // }
    ];

    console.log(this.authService.isLoggedIn);
    if(!this.authService.isLoggedIn) {
      this.router.navigate(['/login']);
    }
  }

}
