/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<ExhibitionLangWithRelations, \'id\' | \'created\' | \'status\' | \'langId\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'created\', \'status\', \'langId\' ] })
 */
export interface ExhibitionLangExcludingIdCreatedStatusLangIdWithRelations { 
    exhibitionId?: number | null;
    name?: string | null;
    description?: string | null;
}

