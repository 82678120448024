/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CountryExcludingIdCreatedStatusLangIdWithRelations } from './countryExcludingIdCreatedStatusLangIdWithRelations';
import { AuctionExcludingIdCreatedStatusLangIdWithRelations } from './auctionExcludingIdCreatedStatusLangIdWithRelations';
import { LimitationExcludingIdCreatedStatusLangIdWithRelations } from './limitationExcludingIdCreatedStatusLangIdWithRelations';


/**
 * (tsType: Omit<NewLimitationBaseWithRelations, \'id\' | \'created\' | \'status\' | \'langId\'>, schemaOptions: { title: \'FrontendLimitationBase\', includeRelations: true, exclude: [ \'id\', \'created\', \'status\', \'langId\' ] })
 */
export interface FrontendLimitationBase { 
    auctionId?: number;
    titleBefore?: string | null;
    firstname: string;
    lastname: string;
    titleAfter?: string | null;
    address?: string | null;
    city?: string | null;
    postCode?: string | null;
    countryId?: number;
    email: string;
    phone: string;
    note?: string;
    recaptchaToken: string;
    auction?: AuctionExcludingIdCreatedStatusLangIdWithRelations;
    foreignKey?: any | null;
    country?: CountryExcludingIdCreatedStatusLangIdWithRelations;
    limitations?: Array<LimitationExcludingIdCreatedStatusLangIdWithRelations>;
}

