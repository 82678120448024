import {Component, Inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HttpHeaders} from '@angular/common/http';
import {ComponentsModule} from '../components.module';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {ConfirmDialog} from "../confirm-dialog/confirm-dialog.component";
import {AuctionService} from "../../../../@backend";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'canvas-image-preview',
  standalone: true,
  imports: [CommonModule, ComponentsModule, MatButtonModule, MatIconModule],
  template: `
  <div class="flex flex-col p-2 w-full h-[calc(70%-1rem)] max-h-full" (click)="close()">
    <img [src]="photo | photoProxy:'rs:fit:500:500:1/g:no':'items':'shared' | async" class="block h-full w-full object-cover object-center cursor-pointer" />
    @if(allowDelete) {
        <button (click)="deleteImage()" mat-flat-button color="accent" class="mt-2"><mat-icon svgIcon="mat:delete"></mat-icon> Smazat</button>
    }
  </div>
  `
})
export class ImagePreviewComponent implements OnInit {

  photo: string;
  shared: boolean;
  allowDelete: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ImagePreviewComponent>) {
  }

  close() {
    this.dialogRef.close(true)
  }

  ngOnInit() {
    this.photo = this.data?.photo ?? '';
    this.shared = this.data?.shared ?? false;
    this.allowDelete = this.data?.allowDelete ?? true;
  }

  async deleteImage() {
    this.dialog.open(ConfirmDialog, {
      data: {
        title: 'Odstranění fotky',
        message: 'Opravdu chcete odstranit fotku?',
        confirmText: 'Ano'
      }
    }).afterClosed().subscribe(async result => {
      if(result) {
        try {
          this.dialogRef.close(true);
        }catch (e) {
          this.snackBar.open('Chyba: ' + e.error.error.message, "OK", {duration: 2000, panelClass: ['bg-red-600']});
        }
      }
    })
  }


}
