/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorPartialExcludingAuctionIdWithRelations } from './authorPartialExcludingAuctionIdWithRelations';
import { ItemPartialExcludingAuctionIdWithRelations } from './itemPartialExcludingAuctionIdWithRelations';
import { AuthorRecordsLangPartialExcludingAuctionIdWithRelations } from './authorRecordsLangPartialExcludingAuctionIdWithRelations';


/**
 * (tsType: Omit<Partial<AuthorRecordsWithRelations>, \'auctionId\'>, schemaOptions: { includeRelations: true, exclude: [ \'auctionId\' ], partial: true })
 */
export interface AuthorRecordsPartialExcludingAuctionIdWithRelations { 
    id?: number;
    authorId?: number;
    itemId?: number;
    type?: AuthorRecordsPartialExcludingAuctionIdWithRelations.TypeEnum;
    position?: number | null;
    author?: AuthorPartialExcludingAuctionIdWithRelations;
    foreignKey?: any | null;
    item?: ItemPartialExcludingAuctionIdWithRelations;
    lang?: AuthorRecordsLangPartialExcludingAuctionIdWithRelations;
    langs?: Array<AuthorRecordsLangPartialExcludingAuctionIdWithRelations>;
}
export namespace AuthorRecordsPartialExcludingAuctionIdWithRelations {
    export type TypeEnum = 'local' | 'europe' | 'worldwide';
    export const TypeEnum = {
        Local: 'local' as TypeEnum,
        Europe: 'europe' as TypeEnum,
        Worldwide: 'worldwide' as TypeEnum
    };
}


