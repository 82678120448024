/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface NewLimitationBase { 
    id?: number;
    auctionId?: number;
    langId?: number | null;
    titleBefore?: string | null;
    firstname: string;
    lastname: string;
    titleAfter?: string | null;
    address?: string | null;
    city?: string | null;
    postCode?: string | null;
    countryId?: number;
    email: string;
    phone: string;
    note?: string;
    status?: NewLimitationBase.StatusEnum;
    created?: string;
    recaptchaToken: string;
}
export namespace NewLimitationBase {
    export type StatusEnum = 'new' | 'inProgress' | 'created' | 'canceled';
    export const StatusEnum = {
        New: 'new' as StatusEnum,
        InProgress: 'inProgress' as StatusEnum,
        Created: 'created' as StatusEnum,
        Canceled: 'canceled' as StatusEnum
    };
}


