/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemExcludingIdWithRelations } from './itemExcludingIdWithRelations';
import { AuthorExcludingIdWithRelations } from './authorExcludingIdWithRelations';
import { AuthorRecordsLangExcludingIdWithRelations } from './authorRecordsLangExcludingIdWithRelations';


/**
 * (tsType: Omit<AuthorRecordsWithRelations, \'id\'>, schemaOptions: { exclude: [ \'id\' ], includeRelations: true })
 */
export interface AuthorRecordsExcludingIdWithRelations { 
    authorId?: number;
    itemId?: number;
    type: AuthorRecordsExcludingIdWithRelations.TypeEnum;
    position?: number | null;
    author?: AuthorExcludingIdWithRelations;
    foreignKey?: any | null;
    item?: ItemExcludingIdWithRelations;
    lang?: AuthorRecordsLangExcludingIdWithRelations;
    langs?: Array<AuthorRecordsLangExcludingIdWithRelations>;
}
export namespace AuthorRecordsExcludingIdWithRelations {
    export type TypeEnum = 'local' | 'europe' | 'worldwide';
    export const TypeEnum = {
        Local: 'local' as TypeEnum,
        Europe: 'europe' as TypeEnum,
        Worldwide: 'worldwide' as TypeEnum
    };
}


