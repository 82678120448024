/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ExhibitionWithRelations } from './exhibitionWithRelations';
import { AuctionFileWithRelations } from './auctionFileWithRelations';
import { AuctionLangWithRelations } from './auctionLangWithRelations';
import { AuctionStatusWithRelations } from './auctionStatusWithRelations';
import { ItemWithRelations } from './itemWithRelations';
import { PressReleaseWithRelations } from './pressReleaseWithRelations';


/**
 * (tsType: AuctionWithRelations, schemaOptions: { includeRelations: true })
 */
export interface AuctionWithRelations { 
    id?: number;
    auctionNumber?: string | null;
    internetAuction?: boolean;
    fee?: number | null;
    limitationFrom?: string | null;
    limitationTo?: string | null;
    auctionFrom?: string | null;
    youtubeVideoId?: string | null;
    mainAuction?: boolean;
    limitationAvailable?: boolean | null;
    archived?: boolean;
    canvasId?: number | null;
    artslimitId?: number | null;
    exhibitionId?: number | null;
    canOrderCatalog?: boolean | null;
    visible?: boolean;
    created?: string;
    status?: number;
    auctionStatus?: AuctionStatusWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionWithRelations;
    files?: Array<AuctionFileWithRelations>;
    lang?: AuctionLangWithRelations;
    langs?: Array<AuctionLangWithRelations>;
    items?: Array<ItemWithRelations>;
    pressReleases?: Array<PressReleaseWithRelations>;
}

