/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemExcludingIdCreatedStatusLangIdWithRelations } from './itemExcludingIdCreatedStatusLangIdWithRelations';


/**
 * (tsType: Omit<LimitationWithRelations, \'id\' | \'created\' | \'status\' | \'langId\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'created\', \'status\', \'langId\' ] })
 */
export interface LimitationExcludingIdCreatedStatusLangIdWithRelations { 
    limitationBaseId?: number | null;
    itemId?: number;
    value?: number | null;
    /**
     * 1: Telephone, 2: Fixed limit
     */
    type: LimitationExcludingIdCreatedStatusLangIdWithRelations.TypeEnum;
    note?: string | null;
    item?: ItemExcludingIdCreatedStatusLangIdWithRelations;
    foreignKey?: any | null;
}
export namespace LimitationExcludingIdCreatedStatusLangIdWithRelations {
    export type TypeEnum = 1 | 2;
    export const TypeEnum = {
        NUMBER_1: 1 as TypeEnum,
        NUMBER_2: 2 as TypeEnum
    };
}


